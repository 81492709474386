import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
import awsBackupDetails from './awsBackupDetails';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'awsBackupDashboard',
  components: {
    awsBackupDetails
  },
  watch: {
    currentPage: function() {
      this.getGstAwsBackup();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getGstAwsBackup();
    }
  },
  data() {
    return {
      awsBackupRowDetails: null,
      showChildModal: false,
      defaultValue: {
        value: null,
        text: null
      },
      minimizeFlag: true,
      countDifference: '1',
      module: {
        value: null,
        text: null
      },
      table_name: {
        value: null,
        text: null
      },
      month: {
        value: null,
        text: null
      },
      countMatchValue: 'all',
      currentPage: 1,
      totalRows: null,
      showValueSetModal: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      loader: false,
      rowDetails: null,
      gridData: [],
      fields: [
        {
          key: 'module_name',
          class: 'col-fix text-center'
        },
        {
          key: 'table_name',
          class: 'text-center'
        },
        {
          key: 'month_name',
          class: 'text-center'
        },
        {
          key: 'total_count_oracle',
          class: 'text-center'
        },
        {
          key: 'total_count_realapp',
          class: 'text-center'
        },
        {
          key: 'backup_type',
          class: 'text-center'
        }
      ],
      unsubscribe: null,
      totalPayload: null
    };
  },
  validations: {
    module: {
      text: {
        required
      }
    },
    month: {
      text: {
        required
      }
    }
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'download') {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.totalPayload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'complainceDashboard/getGstAwsBackup',
            'aws-backup-dashboard',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    hideChildModal(flag) {
      this.showChildModal = flag;
    },
    rowSelected(item) {
      this.awsBackupRowDetails = item;
      this.hideChildModal(true);
    },
    openValueSetModal(vsetCode) {
      this.showValueSetModal = true;
      this.vsetCode = vsetCode;
      this.parent_value_set_id = null;
      if (vsetCode === appStrings.VALUESETTYPE.BACKUP_TABLES) {
        this.parent_value_set_id = this.module.value;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.BACKUP_MODULE) {
        this.module = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.BACKUP_TABLES) {
        this.table_name = {
          value: item.value_set_dtl_id,
          text: item.table_name
        };
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.AWS_BACKUP_MONTHS_NAME
      ) {
        this.month = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      }
      // this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.module.value) {
        this.module = this.defaultValue;
        this.table_name = {
          value: null,
          text: null
        };
      } else if (vsetCode === this.table_name.value) {
        this.table_name = {
          value: null,
          text: null
        };
      } else if (vsetCode === this.month.value) {
        this.month = {
          value: null,
          text: null
        };
      }
    },
    getGstAwsBackup() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          _page: this.currentPage - 1,
          _limit: this.perPage,
          module_name: this.module.text,
          table_name: this.table_name.text,
          month_name: this.month.text,
          count_diff: this.countMatchValue
        };
        this.totalPayload = payload;
        this.loader = true;
        this.$store
          .dispatch('complainceDashboard/getGstAwsBackup', payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              this.gridData = resp.data.data.page;
              this.totalRows = resp.data.data.total_elements;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    clear() {
      this.module.text = null;
      this.month.text = null;
      this.table_name.text = null;
    },
    countMatch(countDifference) {
      if (countDifference === '0') {
        this.countMatchValue = 'unmatch';
      } else if (countDifference === '1') {
        this.countMatchValue = 'all';
      } else if (countDifference === '2') {
        this.countMatchValue = 'match';
      } else {
        this.countMatchValue = null;
      }
    },
    maximize() {
      this.minimizeFlag = !this.minimizeFlag;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
