import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'awsBackupDetails',
  components: {},
  props: ["awsBackupRowDetails"],
  data() {
    return {
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      loader: false,
      gridDataDetails: [
      ],
      awsFields: [
        {
          key: 'table_name',
          class: 'text-center'
        },
        {
          key: 'month_name',
          class: 'text-center'
        },
        {
          key: 'total_count_oracle',
          class: 'text-center'
        },
        {
          key: 'total_count_realapp',
          class: 'text-center'
        },
        {
          key: 'backup_type',
          class: 'text-center'
        }
      ]
    };
  },
  mounted() {
    this.getGstAwsBackupDetails();
  },
  methods: {
    getGstAwsBackupDetails() {
      const payload = {
         backup_hdr_id: this.awsBackupRowDetails.backup_hdr_id
      };
      this.loader = true;
      this.$store
        .dispatch('complainceDashboard/getGstAwsBackupDetailsById', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.gridDataDetails = resp.data.data;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  }
};
